var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "storeRoomForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c("div", { staticClass: "cell-title" }, [
            _c("span", { staticClass: "pre-point" }),
            _vm._v("基本信息"),
          ]),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "用户姓名",
                rules: [
                  { required: true, message: "请选择用户", trigger: "change" },
                ],
                prop: "houseId",
              },
            },
            [
              _c(
                "v-select2",
                _vm._b(
                  {
                    attrs: { width: 400, echoRequestData: _vm.echohouseId },
                    on: { onChange: _vm.houseIdChange },
                    model: {
                      value: _vm.form.houseId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "houseId", $$v)
                      },
                      expression: "form.houseId",
                    },
                  },
                  "v-select2",
                  _vm.houseParams,
                  false
                )
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "车位地址",
                rules: [
                  {
                    required: true,
                    message: "请选择车位地址",
                    trigger: "blur",
                  },
                ],
                prop: "carHouseId",
              },
            },
            [
              _c(
                "v-select2",
                _vm._b(
                  {
                    attrs: { width: 400 },
                    model: {
                      value: _vm.form.carHouseId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "carHouseId", $$v)
                      },
                      expression: "form.carHouseId",
                    },
                  },
                  "v-select2",
                  _vm.carHouseParams,
                  false
                )
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "车位状态",
                rules: [
                  {
                    required: true,
                    message: "请选择车位状态",
                    trigger: "change",
                  },
                ],
                prop: "status",
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.statusOps },
                model: {
                  value: _vm.form.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "类型",
                rules: [
                  { required: true, message: "请选择类型", trigger: "change" },
                ],
                prop: "parkingType",
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.parkingTypeOps },
                model: {
                  value: _vm.form.parkingType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "parkingType", $$v)
                  },
                  expression: "form.parkingType",
                },
              }),
            ],
            1
          ),
          _vm.form.parkingType === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "租期",
                    rules: [
                      {
                        required: true,
                        validator: _vm.validateDate,
                        trigger: "change",
                      },
                    ],
                    prop: "lendEndTime",
                  },
                },
                [
                  _c("v-datepicker", {
                    attrs: {
                      startTime: _vm.form.lendStartTime,
                      endTime: _vm.form.lendEndTime,
                      type: "rangedate",
                    },
                    on: {
                      "update:startTime": function ($event) {
                        return _vm.$set(_vm.form, "lendStartTime", $event)
                      },
                      "update:start-time": function ($event) {
                        return _vm.$set(_vm.form, "lendStartTime", $event)
                      },
                      "update:endTime": function ($event) {
                        return _vm.$set(_vm.form, "lendEndTime", $event)
                      },
                      "update:end-time": function ($event) {
                        return _vm.$set(_vm.form, "lendEndTime", $event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "车位编号", prop: "carNumber" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.carNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "carNumber", $$v)
                  },
                  expression: "form.carNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "完整编号", prop: "carFullNumber" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.carFullNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "carFullNumber", $$v)
                  },
                  expression: "form.carFullNumber",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "车位证明状态" } }, [
            _c("span", [_vm._v(_vm._s(_vm.backData.certificateStatus))]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "车位证明" } },
            _vm._l(_vm.backData.picList, function (item, index) {
              return _c("div", { key: index, staticClass: "flex-content" }, [
                _c("div", { staticClass: "imgBox" }, [
                  _c("img", { attrs: { src: item } }),
                ]),
              ])
            }),
            0
          ),
          _c("el-form-item", { attrs: { label: "共享停车" } }, [
            _c("span", [_vm._v(_vm._s(_vm.backData.sharedStatus))]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }