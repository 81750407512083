// 状态
const statusMap = {
  0: '关闭',
  1: '正常'
}
const certificateStatusMap = {
  0: '待上传',
  1: '待审核',
  2: '已通过',
  3: '未通过'
}
const sharedStatusMap = {
  0: '待开启',
  1: '已开启',
  2: '已关闭'
}
const parkingTypeMap = {
  1: '租',
  2: '售'
}
export {
  statusMap,
  certificateStatusMap,
  sharedStatusMap,
  parkingTypeMap
}
