<template>
  <div class="storeRoomForm-container">
    <form-panel ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore">
        <div class="cell-title"><span class="pre-point"></span>基本信息</div>
        <el-form-item label="用户姓名" :rules="[{ required: true, message: '请选择用户', trigger: 'change' }]" prop="houseId">
          <v-select2 v-model="form.houseId" :width="400" v-bind="houseParams" @onChange="houseIdChange" :echoRequestData="echohouseId"></v-select2>
        </el-form-item>
        <el-form-item label="车位地址" :rules="[{ required: true, message: '请选择车位地址', trigger: 'blur' }]" prop="carHouseId">
          <v-select2 v-model="form.carHouseId" :width="400" v-bind="carHouseParams"></v-select2>
        </el-form-item>
        <el-form-item label="车位状态" :rules="[{ required: true, message: '请选择车位状态', trigger: 'change' }]" prop="status">
          <v-select v-model="form.status" :options="statusOps"></v-select>
        </el-form-item>
        <el-form-item label="类型" :rules="[{ required: true, message: '请选择类型', trigger: 'change' }]" prop="parkingType">
          <v-select v-model="form.parkingType" :options="parkingTypeOps"></v-select>
        </el-form-item>
        <el-form-item v-if="form.parkingType === 1" label="租期" :rules="[{ required: true, validator: validateDate, trigger: 'change' }]" prop="lendEndTime">
          <v-datepicker :startTime.sync="form.lendStartTime" :endTime.sync="form.lendEndTime" type="rangedate" />
        </el-form-item>
        <el-form-item label="车位编号" prop="carNumber">
          <v-input v-model="form.carNumber"></v-input>
        </el-form-item>
        <el-form-item label="完整编号" prop="carFullNumber">
          <v-input v-model="form.carFullNumber"></v-input>
        </el-form-item>
        <el-form-item label="车位证明状态">
          <span>{{backData.certificateStatus}}</span>
        </el-form-item>
        <el-form-item label="车位证明">
          <div class="flex-content" v-for="(item, index) in backData.picList" :key="index">
            <div class="imgBox">
              <img :src="item" />
            </div>
          </div>
        </el-form-item>
        <el-form-item label="共享停车">
          <span>{{backData.sharedStatus}}</span>
        </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import {
  getRoomsList,
  getOwnersList,
  addOwnerParkingUrl,
  updateOwnerParkingUrl,
  getOwnerParkingInfo
} from './api'
import {
  certificateStatusMap,
  sharedStatusMap
} from './map'
export default {
  data () {
    return {
      form: {
        houseId: '',
        userId: '',
        communityId: '',
        carHouseId: '',
        status: 0,
        parkingType: 1,
        lendStartTime: '',
        lendEndTime: '',
        carNumber: '',
        carFullNumber: ''
      },
      submitConfig: {
        // submitContentType: 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      submitUrl: this.$route.query.id ? updateOwnerParkingUrl : addOwnerParkingUrl,
      houseParams: {
        searchUrl: getOwnersList,
        request: {
          text: 'ownerName',
          value: 'houseId'
        },
        response: {
          text: 'name',
          value: 'houseId'
        },
        method: 'get'
      },
      carHouseParams: {
        searchUrl: getRoomsList,
        request: {
          text: 'roomNum',
          value: 'houseId'
        },
        response: {
          text: 'name',
          value: 'id'
        }
      },
      statusOps: [{
        text: '正常',
        value: 1
      }, {
        text: '关闭',
        value: 0
      }],
      parkingTypeOps: [
        {
          text: '租',
          value: 1
        }, {
          text: '售',
          value: 2
        }
      ],
      backData: {
        certificateStatus: '',
        picList: [],
        sharedStatus: ''
      }
    }
  },
  created () {
    let formId = this.$route.query.id
    let userId = this.$route.query.userId
    let houseId = this.$route.query.houseId
    if (formId) {
      this.$setBreadcrumb('编辑')
      this.getFormDetail(formId)
    } else if (userId && houseId) {
      this.$setBreadcrumb('新增')
      this.setUser(userId, houseId)
    } else {
      this.$setBreadcrumb('新增')
    }
  },
  methods: {
    submitBefore () {
      return true
    },
    houseIdChange (val) {
      val && (this.form.userId = val.id)
      val && (this.form.communityId = val.communityId)
    },
    getFormDetail (id) {
      this.$axios.get(getOwnerParkingInfo, {
        params: {
          id
        }
      }).then((res) => {
        if (res.status == 100) { // eslint-disable-line
          let { data } = res
          this.carBrandId = data.carBrandId
          this.form = {
            userId: data.userId,
            houseId: data.houseId,
            communityId: data.communityId,
            carHouseId: data.carHouseId,
            status: data.status,
            parkingType: data.parkingType,
            lendStartTime: data.lendStartTime,
            lendEndTime: data.lendEndTime,
            carNumber: data.carNumber,
            carFullNumber: data.carFullNumber,
            id
          }
          this.backData = {
            certificateStatus: certificateStatusMap[data.certificateStatus],
            picList: data.picList,
            sharedStatus: sharedStatusMap[data.sharedStatus]
          }
        }
      })
    },
    setUser (userId, houseId) {
      this.$set(this.form, 'userId', userId)
      this.$set(this.form, 'houseId', houseId)
    },
    echohouseId (data) {
      let newData = {
        userId: `{"userId":"${this.form.userId}","houseId":"${data.houseId}"}`
      }
      return newData
    },
    validateDate (rule, value, callback) {
      if (!this.form.lendStartTime || !this.form.lendEndTime) {
        callback(new Error('请选择租期'))
      } else {
        callback()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.storeRoomForm-container {
  background: #fff;
  text-align: left;
  padding-left: 20px;
}
.cell-title{
  text-align: left;
  padding-left: 10px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  .pre-point {
    // display: inline-block;
    border-radius: 50%;
    background-color: #dce2e7;
    height: 5px;
    width: 5px;
    margin-right: 5px;
  }
}
.multi-form {
  display: flex;
  align-items: center;
  .mar-left {
    margin-left: 20px;
  }
}
.blueColor{
  color: #337ab7;
}
.flex-content {
  display: flex;
  .imgBox {
    width: 140px;
    height: 140px;
    margin-left: 10px;
    border-radius: 6px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
